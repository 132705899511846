<template>
    <!-- 报告详情 -->
    <div class="reportDetails">
        <div class="backButton">
            <span @click="goBack">
                    <i class="back el-icon-arrow-left"></i>
                返回</span>
        </div>
        <div class="reportDetails-con">
            <div class="title">
                <div class="name">
                    <span class="line"></span>
                    <h4>{{reportList.assessReportName}}</h4>
                </div>
                <div class="time">
                    <span>{{reportList.time | dataFormat}}</span>
                </div>
            </div>
            <div>
                <div class="basic">
                    <div class="portrait">
                        <img :src="headImage" alt="">
                        <span>{{user.name}}</span>

                    </div>
                    <div class="basic-bottom">
                        <div class="chart" v-if="dimensionDtoList && dimensionDtoList.length>1">
                            <div id="main1"  ref="main1" style="width: 360px;height:280px; margin-right:20px"></div>
                        </div>
                        <div class="basic-bottom-right" >
                            <p class="result-score">测评结果<span> {{reportList.modelScore || 0}}</span></p>
                            <div v-if="reportList && reportList.modelAttr  && reportList.modelAttr.attrDefinitionValueList" class="result-detail">
                                <template
                                    v-for="(e,inx) in reportList.modelAttr.attrDefinitionValueList "
                                >
                                    <div :key="inx" class="item-re">
                                        <p class="namex">{{e.name}}</p>
                                        <p class="nameV">{{e.value}}</p>
                                    </div>
                                </template>
                            </div>
                        </div>
                    </div>
                    </div>
            </div>
                <div class="features" v-for="(item, index) in dimensionDtoList" :key="index">
                    <div class="titles">
                        <span class="main">{{item.dimensionName}}</span>
                        <span class="main-score">得分 <span>{{item.dimensionScore || 0}}</span></span>
                    </div>
                    <div class="content  idcat-content">
                        <div class="content-left" v-if="item.indicatorList && item.indicatorList.length>1">
                            <div :id="'demin'+index"  :ref="'demin'+index"  style="width:360px;height:240px;"></div>
                        </div>
                        <ul>
                            <template v-for="(item1, index1) in item.dimensionAttr.attrDefinitionValueList">
                                <li class="oneWay" :key="index1"
                                    v-if="[1, 3].includes(+item1.type)">
                                    <p class="label">{{item1.name}}</p>
                                    <div class="con">
                                        <p>{{item1.value}}</p>
                                    </div>
                                </li>
                                <li :key="index1" v-else>
                                    <p class="label labelMany" v-if="item1.name">{{item1.name}}</p>
                                    <div class="conMany">
                                        <p v-html="item1.value">{{item1.value}}</p>
                                    </div>
                                </li>
                            </template>
                        </ul>
                    </div>
                </div>
            </div>
        <div class="statement">
            <div class="titles">
                <span class="main">免责声明</span>
            </div>
            <div class="content">
                <div>以下说明与本测评报告正文一起构成本测评报告不可分割的统一整体。</div>
                <div>
                    <p>• 本测评报告基于测评者的作答情况而获得，结果代表的是测评者的自我觉知。</p>
                    <p>• 本测评报告正文内容可能引发的与任何第三人之纠纷或冲突，本测评机构不承担任何法律责任。本测评机构亦不在此类纠纷或冲突中充当证人、调停人或其他形式之参与人</p>
                    <p>• 本测评报告不得用于非法之目的，本测评机构不承担任何由此而发生或可能发生之法律责任。</p>
                </div> 
                <div>当本测评报告打印、持有、出具、展示或以其它任何形式使用时，即表明本测评报告之持有人或接触人已审读，理解并同意以上各条款之规定。</div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    name: 'reportDetails',
    components: {},
    data() {
        return {
            id: '',
            reportList: {},
            user: {},
            headImage: '',
            attributeList: {},
            dimensionDtoList: [],
            resultName: ''
        };
    },
    filters: {
        dataFormat(msg) {
            if (msg) {
                const m = msg.split(' ')[0].split('-');
                const t = msg.split(' ')[1].slice(0, -3);
                const s = m.join('.') + ' ' + t;
                return s;
            }
        },
    },
    mounted() {
        this.id = this.$route.query.id;
        if (this.$route.query.type=='result') {
            this.findByResultIdId();
        } else {
            this.findByIdReport();
        }
        this.getPersonalInfo();
    },
    methods: {
        // 我的资料
        getPersonalInfo(){
            this.$api.usercenter.findUser().then(res => {
                if(res.code == 0){
                    this.user = res.data;
                    if (this.user.headImage) {
                        this.headImage = this.user.headImage;
                    } else {
                        this.headImage = require("../../../assets/images/common/userL.png");
                    }
                }
            })
        },
        findByResultIdId() {
            this.$api.assess.findByResultIdId(this.id).then((res) => {
                if(res.data){
                    this.getReportData(res.data);
                }
            });
        },
        findByIdReport() {
            this.$api.assess.findByIdReport(this.id).then((res) => {
                if(res.data){
                    this.getReportData(res.data);
                }
            });
        },
        getReportData(data) {
            this.reportList = data;
            this.dimensionDtoList = this.reportList.dimensionDtoList;
            this.initRadar();
        },
        initRadar(){
            let echartx = {};
            const that = this;
            let demD = [];
            let demDName = [];
            this.dimensionDtoList.map((item,index)=>{
                if(that.dimensionDtoList && that.dimensionDtoList.length>1){
                    demDName.push({
                        name:item.dimensionName,
                        max:item?.max || undefined
                    })
                    demD.push(item.dimensionScore)
                    that.$nextTick(()=>{
                        const myChart = that.$echarts.init(document.getElementById('main1'));
                        myChart.setOption({
                            radar: {
                                indicator: demDName,
                                radius: 70,
                                center: ['50%', '50%'],
                            },
                            tooltip: {
                                trigger: 'item'
                            },
                            series: [
                                {
                                    type: 'radar',
                                    data: [
                                        {
                                            value:demD,
                                            name:that.reportList.assessReportName
                                        }
                                    ]
                                }
                            ]
                        })
                    })
                }
                if(item.indicatorList && item.indicatorList.length>1){
                    const dataD = item.indicatorList.map((e)=>{
                        return e.score
                    })
                    const dataDName = item.indicatorList.map((e)=>{
                        return {
                            name:e.indicatorName,
                             max:e?.max || undefined
                        }
                    })
                    that.$nextTick(()=>{
                        console.log(index);
                        echartx[index]= that.$echarts.init(document.getElementById('demin'+index));
                        echartx[index].setOption({
                            radar: {
                                indicator: dataDName,
                                radius: 70,
                                center: ['50%', '50%'],
                            },
                            tooltip: {
                                trigger: 'item'
                            },
                            series: [
                                {
                                    type: 'radar',
                                    data: [
                                        {
                                        value:dataD,
                                        name:item.dimensionName
                                        }
                                    ]
                                }
                            ]
                        })
                    })
                }
            })
        },
        // 返回
        goBack() {
            sessionStorage.setItem('userCenterCurrentTab','我的测评')
            this.$router.go(-1)
        },
    }
};
</script>

<style scoped lang="stylus">
@import '../css/reportDetails.styl';
</style>
