<template>
    <!-- 人才智能 -->
    <div class="assessList">
        <div class="title">人才测评</div>
        <evaluation-list :navPage="navPage"></evaluation-list>
    </div>
</template>
<script>
import EvaluationList from '../../../layout/business/evaluationList.vue';
export default {
    name: 'assessList',
    components: { EvaluationList },
    data() {
        return {
            navPage: 'assessList'
        };
    },
    filters: {},
    computed: {},
    methods: {},
    mounted() {}
};
</script>

<style scoped lang="stylus">
@import '../css/assessList.styl';
</style>
